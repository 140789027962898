import React from "react";
import FooterLogo from "../assets/images/footerSvg.svg";
import logo from '../assets/images/FooterLogo.jpg'

function getCurrentFormattedDate() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  const hour = String(now.getHours() % 12 || 12).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const ampm = now.getHours() >= 12 ? 'PM' : 'AM';

  return `${year}-${month}-${day} ${hour}:${minute} ${ampm}`;
}

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const formattedDate = getCurrentFormattedDate();

  const footerLink = [
    { label: "Home", url: "/" },
    { label: "About Protean", url: "https://www.proteantech.in/" },
    { label: "Contact Us", url: "/nsdl-addresses" },
    { label: "TIN Facilitation Centers", url: "/link19" },
    { label: "Guided Tour", url: "/guided/guide-status-track" },
    { label: "Publications", url: "/about-us" },
    { label: "Related Links", url: "https://www.protean-tinpan.com/related-link.html" },
    { label: "Site MAP", url: "https://www.protean-tinpan.com/sitemap.html" },
    { label: "Disclaimer", url: "https://www.protean-tinpan.com/disclaimer.html" },
    { label: "Privacy Policy", url: "/privacy-policy" },
    { label: "Terms and Condition", url: "https://www.protean-tinpan.com/terms-and-condition.html" },
    { label: "Hyperlinking Policy", url: "https://www.protean-tinpan.com/hyperlinking-policy.html" },
  ]

  return (
    <footer className="bg-black relative  flex justify-end items-end lg:min-h-[416px]">
      <img
        className="absolute bottom-0 left-0 z-10"
        width={"380px"}
        height={"382px"}
        src={FooterLogo}
        alt="Footer Decoration"
      />
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 z-40">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 z-40">
            <img
              src={logo}
              alt="protean"
              width={'179px'}
              height={'48px'}
            />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4 md:w-[50vw]">
            {footerLink.map((footerLabel, key) => (
              <a key={key} href={footerLabel.url} className="text-sm md:w-[151px] hover:underline uppercase text-white">
                {footerLabel.label}
              </a>
            ))}
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Updated as on 2024-08-27 10:00 AM
          </span>
          <div className="flex flex-col mt-4 sm:justify-center sm:mt-0">
            <p className="text-[#FFFFFF] text-[14px] font-light">
              <a target="_blank" href="https://www.proteantech.in/">
              Copyright © {currentYear} | Protean eGov Technologies Limited (Formerly
              NSDL e-Governance Infrastructure Limited)
              </a>
            </p>
            <p className="text-[#FFFFFF] text-[14px] font-light">
              Site best viewed in IE 11+, Google Chrome 61+, Mozilla Firefox
              50+, Microsoft Edge 14+ and Safari for Mac 5.1+.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;